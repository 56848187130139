$orange: #ee9a00;

// Path to Bulma's sass folder
@use "~bulma/sass" with (
  $primary: $orange
);

@use "syntax-highlighting";

body.postcard {
  background-image: -webkit-image-set(
    url("/images/bay-bridge-from-bart-postcard.webp"),
    url("/images/bay-bridge-from-bart-postcard.jpg"));
  background-image: image-set(
    url("/images/bay-bridge-from-bart-postcard.webp") type("image/webp"),
    url("/images/bay-bridge-from-bart-postcard.jpg") type("image/jpeg"));

  background-repeat: no-repeat;
  background-position: top -30px center; // show a bit more of the tops of the buildings
}

.ruby-red {
  background-color:#a91401;

  & p {
    @extend .has-text-white
  }
}

// the content class give descendant p elements a margin bottom; this adds the same to code blocks
.content .language-ruby {
  margin-bottom: 16px;
}

// bulma removes the underline from links in some cases; this puts it back for blog posts
.content p a {
  text-decoration: underline;
}

.highlight-logo {
	float: right;
	max-width: 25%;
}

.linked-in-logo {
  height: 1.5rem;
}

main .section {
  padding-bottom: 0;
}

.section + .section {
  padding-top: 0;
}

@media (prefers-color-scheme: dark) {
  .github.icon {
    content: url("/images/github-mark-white.png")
  }
}

.recommendations {
  display: grid;
  grid-template: 1/1;
  align-items: center;
}

.recommendation {
  grid-area: 1/1;
  display: block;
  transition: all .6s .6s cubic-bezier(.5, 0, .5, 1);
  transition-property: opacity, transform;
}

.recommendation[hidden] {
  transition-delay: 0;
  opacity: 0;
  transform: scale(0.8);
}

.post-summary:hover {
  background-color:  hsl(0, 0%, 96%);
}

.link-preview-card {
  max-width: 400px; /* Max width for the card */
  margin: 20px;
}

.card-image-img {
  max-width: 100%;  /* Ensure the image doesn't overflow */
  height: auto;     /* Maintain aspect ratio */
  max-height: 200px; /* Optional: control image height */
  object-fit: cover; /* Make sure image covers the space */
}

.card-content {
  padding: 15px;
}

/* Optional: Adjust for small screens */
@media (max-width: 768px) {
  .link-preview-card {
    max-width: 100%; /* Full width on smaller screens */
  }
}

div.media-content > article.media {
  margin-left: -40px;
}
